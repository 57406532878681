@tailwind base;
@tailwind components;
@tailwind utilities;



.cedarville-cursive-regular {
  font-family: "Cedarville Cursive", cursive;
  font-weight: 400;
  font-style: normal;
}